import styled from 'styled-components';
import Collapse from '../../components/Collapse';
import { mediaQueries } from '../../utils/mediaQueries';

export const Container = styled.div`
  margin: 20px auto;

  ${mediaQueries('md')} {
    margin: 0;
  }
`;

export const Image = styled.div`
  width: 300px;
  text-align: center;
  .gatsby-image-wrapper {
    margin: 0 auto;
    & > div {
      padding-bottom: 300px !important;
    }
  }

  picture {
    img {
      object-fit: contain !important;
      max-height: 300px;
    }
  }

  ${mediaQueries('md')} {
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
  }
`;

export const Info = styled.div`
  width: 650px;
`;
export const Name = styled.h1`
  font-family: 'futural', Arial, '微軟正黑體', Helvetica, sans-serif;
  color: ${(props) => props.theme.headerColor};
  font-size: 36px;
  margin-bottom: 10px;
  font-weight: normal;
  line-height: normal;
`;

/*export const Shop = styled.div`
  position:relative;
  display:inline-block;
  font-family: 'futural', Arial, '微軟正黑體', Helvetica, sans-serif;
  color: white !important;
  font-size: 16px;
  background:#173e77;
  border-radius:40px;
  padding: 7.5px 40px;
  margin-top:15px;
  a{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height: 100%;
  }

  ${mediaQueries('md')} {
    display: block;
    width: 40%;
    margin: 0 auto 10% auto;
    text-align:center;
    padding: 7.5px 0;
  }
`;*/

export const Item = styled.div`
  color: ${(props) => props.theme.prodcut.descColor};
  font-size: 12px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Title = styled.div`
  width: 15%;
`;

export const Content = styled.div`
  width: 75%;
`;

export const CollapseStyled = styled(Collapse)`
  .panel {
    &.active {
      .panel__content {
        padding: 20px 20px;
      }
    }

    &:nth-child(3) {
      .panel__content {
        padding: 0;
      }
    }
  }
`;


export const Shop = styled.div`
  position:relative;
  display:inline-block;
  font-family: 'futural', Arial, '微軟正黑體', Helvetica, sans-serif;
  color: white ;
  font-size: 16px;
  background:${(props) => props.theme.button.bgColor};
  border-radius:40px;
  padding: 7.5px 30px;
  margin-top:15px;
  border:1px solid ${(props) => props.theme.button.bgColor};
  img{
    width:25px;
    padding-right:5px;
    transform:translateY(-5%);
  }
  a{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height: 100%;
  }

  &:hover{
    color:${(props) => props.theme.button.bgColor};
    background:white;
  }

  ${mediaQueries('md')} {
    display: block;
    width: 40%;
    margin: 0 auto 10% auto;
    text-align:center;
    padding: 7.5px 0;
  }
`;