import React, { useEffect } from 'react';
import ImgFb from '../../assets/images/addthis/facebookthis.png';
import ImgTwitter from '../../assets/images/addthis/twitterthis.png';
import ImgSina from '../../assets/images/addthis/sinaweibo.png';
import ImgMail from '../../assets/images/addthis/mailthis.png';
import ImgPlus from '../../assets/images/addthis/adthis.png';
import styled from 'styled-components';

const Container = styled.div`
  display: table;
  margin: 40px auto;
  &>div>a{
    padding:0 2px;
  }
`;

const AddThis = () => {
  useEffect(() => {
    setTimeout(() => {
      var addthisScript = document.createElement('script');
      addthisScript.setAttribute(
        'src',
        '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-4f334ec95f549666'
      );
      if (document.body) document.body.appendChild(addthisScript);
    });
  }, []);

  return (
    <Container className="addthis_toolbox addthis_default_style">
      <div className="addthis_toolbox addthis_default_style">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" className="addthis_button_facebook at300b">
          <img alt="facebook" src={ImgFb} width="24" height="24" border="0" />
        </a>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" className="addthis_button_twitter at300b">
          <img
            alt="twitter"
            src={ImgTwitter}
            width="24"
            height="24"
            border="0"
          />
        </a>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" className="addthis_button_sinaweibo at300b">
          <img
            alt="Sina Weibo"
            src={ImgSina}
            width="24"
            height="24"
            border="0"
          />
        </a>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" className="addthis_button_email at300b">
          <img alt="Email" src={ImgMail} width="24" height="24" border="0" />
        </a>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className="addthis_button at300b"
          href="http://www.addthis.com/bookmark.php?v=300&amp;pubid=ra-4f334ec95f549666"
        >
          <img src={ImgPlus} width="24" height="24" border="0" alt="Share" />
        </a>
      </div>
    </Container>
  );
};

export default AddThis;
